import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": false,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "light",
  "types": ["product", "research"],
  "status": ["completed"],
  "title": "Pizza Trashcan",
  "subtitle": "",
  "description": "",
  "years": ["2013"],
  "institutions": ["aau-cph"],
  "location": "cph",
  "keywords": {
    "topics": ["sustainable design"],
    "tools": []
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Designer"
  }, {
    "name": "Dennis",
    "role": "Designer"
  }, {
    "name": "Jacob",
    "role": "Designer"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* # Pizza Trashcan */
    }
    <h2>{`Description`}</h2>
    <p>{`There is a lot of pizza trays lying around during the night and in the mornings of popular party streets in capitals. A researching.
Cleaing Gothersgade from Pizza trays
Pizza Prism`}</p>
    <h2>{`Media`}</h2>
    <ul>
      <li parentName="ul">{`Video`}</li>
      <li parentName="ul">{`Report (Front page)`}</li>
      <li parentName="ul">{`Trashcans today`}</li>
    </ul>
    <h2>{`Pizza cardboard trashcan`}</h2>
    <h2>{`See Gothersgade i dag`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      